<template>
  <land-section
    id="home_service"
    :color="classify.backColor || 'transparent'"
    :space="classify.space || 80"
  >
    <land-section-heading
      v-if="header.title"
      :title="header.title"
      :icon="header.icon"
      :outlined="header.outlined"
    >
      {{ header.subtitle }}
    </land-section-heading>

    <v-container
      style="max-width: 1000px; height: 100%;"
    >
      <v-row class="mx-10">
        <v-col
          v-for="(feature, i) in features"
          :key="`feature_${i}`"
          cols="12"
          md="4"
        >
          <div
            class="d-flex flex-column justify-center align-center"
            :class="[
              mdAndUp && (i < (features.length - 1)) ? 'co-border-r co-border-gray-400' : ''
            ]"
          >
            <img
              v-if="feature.image"
              :src="feature.image"
              :height="mdAndUp ? 48 : 32"
              cover
            >
            <div class="co-flex-col co-justify-center co-items-center mt-4">
              <span
                v-for="txt in feature.texts"
                :key="txt"
              >
                {{ txt }}
              </span>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </land-section>
</template>

<script>
  export default {
    name: 'IntroFeatures',
    data () {
      return {
        classify: {
          backColor: 'white',
          space: '20'
        },
        header: {
          title: '我们能为您提供什么？',
          subtitle: '',
          fluid: true
        },
        features: [{
          image: 'https://oss.landcoo.com/yujian/1645455583215751168.png',
          title: '',
          subtitle: '打造专属社区海量云存储空间运营个人品牌',
          texts: [
            '打造专属社区',
            '海量云存储空间',
            '运营个人品牌'
          ]
        }, {
          image: 'https://oss.landcoo.com/yujian/1645455901135605760.png',
          title: '',
          subtitle: '多平台媒介全方位展示海量优质摄影作品',
          texts: [
            '平台媒介',
            '全方位展示海量',
            '优质摄影作品'
          ]
        }, {
          image: 'https://oss.landcoo.com/yujian/1645455979019636736.png',
          title: '',
          subtitle: '参与专题活动提升自己摄影水平分享精彩瞬间',
          texts: [
            '参与专题活动',
            '提升自己摄影水平',
            '分享精彩瞬间'
          ]
        }]
      }
    },
    computed: {
      smAndDown () {
        return this.$vuetify.breakpoint.smAndDown
      },
      mdAndUp () {
        return this.$vuetify.breakpoint.mdAndUp
      }
    },
  }
</script>
